@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Poetsen One;
    src: url('/shared-assets/fonts/PoetsenOne-Regular.ttf') format('truetype');
    font-display: swap;
  }
}

.cos-standard-text-shadow {
  text-shadow: rgb(0 0 0) 0px 0px 3px, rgb(0 0 0) 0px 0px 3px, rgb(0 0 0) 0px 0px 3px,
    rgb(0 0 0) 0px 0px 3px, rgb(0 0 0) 0px 0px 3px, rgb(0 0 0) 0px 0px 3px;
}

.cos-small-text-shadow {
  text-shadow: rgb(0 0 0) 0px 0px 1px, rgb(0 0 0) 0px 0px 1px, rgb(0 0 0) 0px 0px 1px,
    rgb(0 0 0) 0px 0px 1px, rgb(0 0 0) 0px 0px 1px, rgb(0 0 0) 0px 0px 1px;
}

.cos-salepoints-text-shadow {
  text-shadow: rgb(108 46 38) 0px 0px 4px, rgb(108 46 38) 0px 0px 4px, rgb(108 46 38) 0px 0px 4px,
    rgb(108 46 38) 0px 0px 4px, rgb(108 46 38) 0px 0px 4px, rgb(108 46 38) 0px 0px 4px;
}

.animateIn {
  transform: scale(0.8);
  opacity: 0%;
  animation: animate-in 0.3s cubic-bezier(0.175, 0.785, 0.3, 1.575) 1;
  animation-fill-mode: forwards;
  animation-delay: var(--animation-delay);
}

@keyframes animate-in {
  0% {
    transform: scale(0.8);
    opacity: 0%;
  }
  100% {
    transform: scale(1);
    opacity: 100%;
  }
}
